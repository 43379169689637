<template>
    <div class="page default-user-page" @keyup.13="onSaveClick">
        <div class="page__title">
            <nav-back />
            Настройка дефолтных значений игрока
            <ui-button color="blue" class="page__title-button" @clicked="onSaveClick">Сохранить</ui-button>
        </div>
        <div class="default-user-page__user-field">
            <label class="my-0 mr-2">Взрослый контент:</label>
            <b-dropdown :text="adultOptionText">
                <b-dropdown-item
                    v-for="(option, i) of dropdownOptions" :key="i" @click="onAdultOptionChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="default-user-page__user-field">
            <label class="my-0 mr-2">VIP режим:</label>
            <b-dropdown :text="vipOptionText">
                <b-dropdown-item
                    v-for="(option, i) of dropdownOptions" :key="i" @click="onVipOptionChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="default-user-page__user-field">
            <label class="my-0 mr-2">Режим тестировщика:</label>
            <b-dropdown :text="testerOptionText">
                <b-dropdown-item
                    v-for="(option, i) of dropdownOptions" :key="i" @click="onTesterOptionChanged"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="default-user-page__user-field">
            <label class="my-0 mr-2">История по умолчанию:</label>
            <input
                type="number" class="form-control" v-model.number="defaultStoryId"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
        <div class="default-user-page__user-field">
            <label class="my-0 mr-2">Активная история:</label>
            <input
                type="number" class="form-control" v-model.number="latestStoryId"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
        
        <h3 class="my-3">Стартовый баланс</h3>
        <div class="default-user-page__user-field" v-for="(value, id) in balances" :key="id">
            <label>{{ getCurrencyName(id) }}</label>
            <input
                type="number" class="form-control" v-model.number="balances[id]"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
        
        <h3 class="my-3">События</h3>
        <div data-event="NotEnoughCrystalsEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">NotEnoughCrystalsEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="notEnoughCrystalsEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onNotEnoughCrystalsEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="default-user-page__user-field">
                <label>IsTimer</label>
                <b-dropdown :text="notEnoughCrystalsTimerText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onNotEnoughCrystalsTimerOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.NotEnoughCrystalsEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="NotEnoughCrystalsEvent"
                />
            </div>
        </div>
        
        <div data-event="NotEnoughKeysEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">NotEnoughKeysEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="notEnoughKeysEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onNotEnoughKeysEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="default-user-page__user-field">
                <label>IsTimer</label>
                <b-dropdown :text="notEnoughKeysTimerText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onNotEnoughKeysTimerOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.NotEnoughKeysEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="NotEnoughKeysEvent"
                />
            </div>
        </div>
        
        <div data-event="StoryFinishedEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">StoryFinishedEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="storyFinishedEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onStoryFinishedEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.StoryFinishedEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="StoryFinishedEvent"
                />
            </div>
        </div>
        
        <div data-event="ChapterFinishedEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">ChapterFinishedEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="chapterFinishedEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onChapterFinishedEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.ChapterFinishedEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="ChapterFinishedEvent"
                />
            </div>
        </div>
        
        <div data-event="PaidChoiceEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">PaidChoiceEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="paidChoiceEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onPaidChoiceEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.PaidChoiceEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="PaidChoiceEvent"
                />
            </div>
        </div>
        
        <div data-event="FreeChoiceEvent" class="mb-3">
            <h2 class="my-2 font-weight-bold">FreeChoiceEvent</h2>
            <div class="default-user-page__user-field">
                <label>IsEnabled</label>
                <b-dropdown :text="freeChoiceEnabledText">
                    <b-dropdown-item
                        v-for="(option, i) of dropdownOptions" :key="i" @click="onFreeChoiceEnabledOptionChanged"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <p>Bonuses</p>
            <hr />
            <div>
                <stage-item-bonus
                    v-for="([stageName, stage], i) in Object.entries(config.FreeChoiceEvent.Bonuses)" :key="i"
                    v-bind="stage" :stageName="stageName" eventName="FreeChoiceEvent"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/plugins/bus';
import { getCurrencyName } from '@/utils';

import StageItemBonus from '../../components/StageItemBonus.vue';

export default {
    data: () => ({
        adultOption: 0,
        adultOptionText: '',
        
        vipOptionText: '',
        vipOption: 0,
        
        testerOptionText: '',
        testerOption: 0,
        
        notEnoughCrystalsEnabledText: '',
        notEnoughCrystalsEnabledOption: 0,
        notEnoughCrystalsTimerText: '',
        notEnoughCrystalsTimerOption: 0,
        
        notEnoughKeysEnabledText: '',
        notEnoughKeysEnabledOption: 0,
        notEnoughKeysTimerText: '',
        notEnoughKeysTimerOption: 0,
        
        storyFinishedEnabledText: '',
        storyFinishedEnabledOption: 0,
        
        chapterFinishedEnabledText: '',
        chapterFinishedEnabledOption: 0,
        
        paidChoiceTimerText: '',
        paidChoiceTimerOption: 0,
        paidChoiceEnabledText: '',
        paidChoiceEnabledOption: 0,
        
        freeChoiceTimerText: '',
        freeChoiceTimerOption: 0,
        freeChoiceEnabledText: '',
        freeChoiceEnabledOption: 0,
        
        dropdownOptions: [
            { value: true, name: 'Включено' },
            { value: false, name: 'Выключено' },
        ],
        
        defaultStoryId: 0,
        latestStoryId: 0,
        
        balances: {}
    }),
    components: {
        NavBack: () => import('../../components/navigation/NavBack'),
        UiButton: () => import('../../components/ui/UiButton'),
        StageItemBonus
    },
    computed: {
        ...mapGetters('firebase', ['stories', 'currencies']),
        config() {
            return this.$store.getters['firebase/defaultUserConfig'];
        }
    },
    async created() {
        await this.getConfig();
        
        this.setData();
        
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'saveDefaultUserConfig']),
        getCurrencyName(id) {
            return getCurrencyName(id);
        },
        async onSaveClick() {
            const Currencies = Object.entries(this.balances).map(([id, value]) => ({ id: +id, value }));
            
            const response = await this.saveDefaultUserConfig({
                AdultModeEnabled: this.adultOption,
                VipModeEnabled: this.vipOption,
                TesterModeEnabled: this.testerOption,
                DefaultStoryId: this.defaultStoryId,
                LatestStoryId: this.latestStoryId,
                Currencies,
            });
            
            if (!response.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
        },
        onAdultOptionChanged(event) {
            this.adultOption = !!(event.target.dataset.value || 0);
            this.adultOptionText = this.dropdownOptions.find(opt => opt.value === this.adultOption).name;
        },
        onVipOptionChanged(event) {
            this.vipOption = !!(event.target.dataset.value || 0);
            this.vipOptionText = this.dropdownOptions.find(opt => opt.value === this.vipOption).name;
        },
        onTesterOptionChanged(event) {
            this.testerOption = !!(event.target.dataset.value || 0);
            this.testerOptionText = this.dropdownOptions.find(opt => opt.value === this.testerOption).name;
        },
        
        onNotEnoughCrystalsEnabledOptionChanged(event) {
            this.notEnoughCrystalsEnabledOption = !!(event.target.dataset.value || 0);
            this.notEnoughCrystalsEnabledText = this.dropdownOptions.find(opt => opt.value === this.notEnoughCrystalsEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'NotEnoughCrystalsEvent',
                property: 'IsEnabled',
                value: this.notEnoughCrystalsEnabledOption
            });
        },
        onNotEnoughCrystalsTimerOptionChanged(event) {
            this.notEnoughCrystalsTimerOption = !!(event.target.dataset.value || 0);
            this.notEnoughCrystalsTimerText = this.dropdownOptions.find(opt => opt.value === this.notEnoughCrystalsTimerOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'NotEnoughCrystalsEvent',
                property: 'IsTimer',
                value: this.notEnoughCrystalsTimerOption
            });
        },
        
        onNotEnoughKeysEnabledOptionChanged(event) {
            this.notEnoughKeysEnabledOption = !!(event.target.dataset.value || 0);
            this.notEnoughKeysEnabledText = this.dropdownOptions.find(opt => opt.value === this.notEnoughKeysEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'NotEnoughKeysEvent',
                property: 'IsEnabled',
                value: this.notEnoughKeysEnabledOption
            });
        },
        onNotEnoughKeysTimerOptionChanged(event) {
            this.notEnoughKeysTimerOption = !!(event.target.dataset.value || 0);
            this.notEnoughKeysTimerText = this.dropdownOptions.find(opt => opt.value === this.notEnoughKeysTimerOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'NotEnoughKeysEvent',
                property: 'IsTimer',
                value: this.notEnoughKeysTimerOption
            });
        },
        
        onStoryFinishedEnabledOptionChanged(event) {
            this.storyFinishedEnabledOption = !!(event.target.dataset.value || 0);
            this.storyFinishedEnabledText = this.dropdownOptions.find(opt => opt.value === this.storyFinishedEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'StoryFinishedEvent',
                property: 'IsEnabled',
                value: this.storyFinishedEnabledOption
            });
        },
        
        onChapterFinishedEnabledOptionChanged(event) {
            this.chapterFinishedEnabledOption = !!(event.target.dataset.value || 0);
            this.chapterFinishedEnabledText = this.dropdownOptions.find(opt => opt.value === this.chapterFinishedEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'ChapterFinishedEvent',
                property: 'IsEnabled',
                value: this.chapterFinishedEnabledOption
            });
        },
        
        onPaidChoiceEnabledOptionChanged(event) {
            this.paidChoiceEnabledOption = !!(event.target.dataset.value || 0);
            this.paidChoiceEnabledText = this.dropdownOptions.find(opt => opt.value === this.paidChoiceEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'PaidChoiceEvent',
                property: 'IsEnabled',
                value: this.paidChoiceEnabledOption
            });
        },
        
        onFreeChoiceEnabledOptionChanged(event) {
            this.freeChoiceEnabledOption = !!(event.target.dataset.value || 0);
            this.freeChoiceEnabledText = this.dropdownOptions.find(opt => opt.value === this.freeChoiceEnabledOption).name;
            
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT', {
                eventName: 'FreeChoiceEvent',
                property: 'IsEnabled',
                value: this.freeChoiceEnabledOption
            });
        },
        
        setData() {
            this.adultOption = this.config.AdultModeEnabled || false;
            this.vipOption = this.config.VipModeEnabled || false;
            this.testerOption = this.config.TesterModeEnabled || false;
            this.defaultStoryId = this.config.DefaultStoryId || 0;
            this.latestStoryId = this.config.LatestStoryId || 0;
            
            this.notEnoughCrystalsEnabledOption = this.config.NotEnoughCrystalsEvent?.IsEnabled || false;
            this.notEnoughCrystalsTimerOption = this.config.NotEnoughCrystalsEvent?.IsTimer || false;
            
            this.notEnoughKeysEnabledOption = this.config.NotEnoughKeysEvent?.IsEnabled || false;
            this.notEnoughKeysTimerOption = this.config.NotEnoughKeysEvent?.IsTimer || false;
            
            this.storyFinishedEnabledOption = this.config.StoryFinishedEvent?.IsEnabled || false;
            
            this.chapterFinishedEnabledOption = this.config.ChapterFinishedEvent?.IsEnabled || false;
            
            this.paidChoiceEnabledOption = this.config.PaidChoiceEvent?.IsEnabled || false;
            
            this.freeChoiceEnabledOption = this.config.FreeChoiceEvent?.IsEnabled || false;
            
            this.adultOptionText = this.dropdownOptions.find(opt => opt.value === this.adultOption).name;
            this.vipOptionText = this.dropdownOptions.find(opt => opt.value === this.vipOption).name;
            this.testerOptionText = this.dropdownOptions.find(opt => opt.value === this.testerOption).name;
            
            this.notEnoughCrystalsEnabledText = this.dropdownOptions.find(opt => opt.value === this.notEnoughCrystalsEnabledOption).name;
            this.notEnoughCrystalsTimerText = this.dropdownOptions.find(opt => opt.value === this.notEnoughCrystalsTimerOption).name;
            
            this.notEnoughKeysEnabledText = this.dropdownOptions.find(opt => opt.value === this.notEnoughKeysEnabledOption).name;
            this.notEnoughKeysTimerText = this.dropdownOptions.find(opt => opt.value === this.notEnoughKeysTimerOption).name;
            
            this.storyFinishedEnabledText = this.dropdownOptions.find(opt => opt.value === this.storyFinishedEnabledOption).name;
            
            this.chapterFinishedEnabledText = this.dropdownOptions.find(opt => opt.value === this.storyFinishedEnabledOption).name;
            
            this.paidChoiceEnabledText = this.dropdownOptions.find(opt => opt.value === this.paidChoiceEnabledOption).name;
            
            this.freeChoiceEnabledText = this.dropdownOptions.find(opt => opt.value === this.paidChoiceEnabledOption).name;
            
            this.balances = {};
            this.config.Currencies.forEach(currency => this.balances[currency.id] = currency.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.default-user-page {
    text-align: left;
    
    &__user-field {
        display: grid;
        margin-bottom: 12px;
        grid-template-columns: repeat(2, minmax(100px, 200px));
        
        label {
            display: flex;
            align-items: center;
        }
    }
}
</style>

<template>
    <div class="mb-3">
        <h5><b>{{ stageName }}</b></h5>
        <div class="stage-item-bonus">
            <label class="my-0 mr-2 flex-shrink-0">Target</label>
            <input
                type="number" class="form-control" v-model.number="target"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
        <div class="stage-item-bonus">
            <label>Reward currency</label>
            <input
                type="number" class="form-control" v-model.number="rewardCurrencyId"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
        <div class="stage-item-bonus">
            <label>Reward value</label>
            <input
                type="number" class="form-control" v-model.number="rewardValue"
                min="0" oninput="validity.valid||(value=0)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        eventName: String,
        stageName: String,
        Target: Number,
        Reward: Object,
        AdsBonus: Object
    },
    data: () => ({
        target: 0,
        rewardCurrencyId: 0,
        rewardValue: 0,
        adsBonusCurrencyId: 0,
        adsBonusValue: 0
    }),
    created() {
        this.target = this.Target;
        this.rewardCurrencyId = this.Reward.id;
        this.rewardValue = this.Reward.value;
        this.adsBonusCurrencyId = this.AdsBonus.id;
        this.adsBonusValue = this.AdsBonus.value;
    },
    watch: {
        target(value) {
            this.updateBonusProperty('Target', value);
        },
        rewardCurrencyId(value) {
            this.updateBonusProperty('Reward', { id: value, value: this.rewardValue });
        },
        rewardValue(value) {
            this.updateBonusProperty('Reward', { id: this.rewardCurrencyId, value });
        },
        adsBonusCurrencyId(value) {
            this.updateBonusProperty('AdsBonus', { id: value, value: this.adsBonusValue });
        },
        adsBonusValue(value) {
            this.updateBonusProperty('AdsBonus', { id: this.adsBonusCurrencyId, value });
        }
    },
    methods: {
        updateBonusProperty(property, value) {
            this.$store.commit('firebase/UPDATE_DEFAULT_USER_EVENT_BONUSES', {
                eventName: this.eventName,
                stageName: this.stageName,
                property,
                value
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.stage-item-bonus {
    display: grid;
    grid-template-columns: 200px 100px;
    margin-bottom: 4px;
    vertical-align: middle;
    align-items: center;
    
    label {
        margin: 0;
    }
}
</style>
